import { Link } from 'react-router-dom';

const Page404 = () => {
  document.title = 'Admin';

  return (
    <div>
      <h2>Error 404</h2>
      <p>Page not found</p>

      <Link to="/">
        <button>return home</button>
      </Link>
    </div>
  );
};

export default Page404;
