import { Button } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { postLogin } from '../utils/fetch';

const Login = () => {
  const [inputUsername, setInputusername] = useState('');
  const [inputPassword, setInputpassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation(); // import useLocation hook from react-router-dom

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputusername(event.target.value.trim());
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputpassword(event.target.value.trim());
  };

  async function handleClick() {
    try {
      const resp = await postLogin(inputUsername, inputPassword);
      if (resp.access_token) {
        const nextPage = new URLSearchParams(location.search).get('next');
        if (nextPage) {
          window.location.href = nextPage;
        } else {
          window.location.href = '/admin';
        }
      } else {
        setMessage(resp.msg);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleClick();
    }
  }

  document.title = 'Login';

  return (
    <div>
      <h1>Admin Login</h1>
      <form>
        <label
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <input
            type="text"
            name="username"
            placeholder="username"
            onChange={handleChangeUsername}
            onKeyPress={handleKeyPress}
            style={{
              padding: 10,
              margin: 10,
              backgroundColor: '#fbfbfb',
            }}
          />
          <input
            type="password"
            name="password"
            placeholder="password"
            onChange={handleChangePassword}
            onKeyPress={handleKeyPress}
            style={{
              padding: 10,
              margin: 10,
              backgroundColor: '#fbfbfb',
            }}
          />
        </label>
      </form>
      <Button
        disabled={inputUsername.length === 0 || inputPassword.length === 0}
        onClick={() => handleClick()}
        color="info"
        variant={
          inputUsername.length === 0 || inputPassword.length === 0
            ? 'outlined'
            : 'contained'
        }
        sx={{ margin: 1 }}
      >
        Login
      </Button>
      <p>
        <i>{message}</i>
      </p>
      <Button
        onClick={() => {
          window.location.href = '/';
        }}
        color="info"
        sx={{ margin: 1 }}
      >
        Return home
      </Button>
    </div>
  );
};

export default Login;
