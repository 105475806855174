function epochToUTCString(epoch: number) {
  const myDate = new Date(epoch * 1000);
  return myDate.toUTCString();
}

function epochToLocale(epoch: number) {
  const myDate = new Date(epoch * 1000);
  return myDate.toLocaleString();
}

export { epochToLocale, epochToUTCString };
