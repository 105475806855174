import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton = (props: {
  currentUrl?: string;
  href: string;
  outlined?: boolean;
  children: React.ReactNode;
}) => {
  if (props.currentUrl === props.href) {
    return <></>;
  }
  return (
    <Link to={props.href} style={{ textDecoration: 'none' }}>
      <Button variant={props.outlined ? 'outlined' : 'contained'}>
        {props.children}
      </Button>
    </Link>
  );
};

const AdminMenu = () => {
  const currentUrl = window.location.pathname;
  return (
    <div style={{ display: 'flex' }}>
      <Grid container gap={1}>
        <LinkButton href="/admin/add" currentUrl={currentUrl}>
          add match results
        </LinkButton>
        <LinkButton href="/admin/edit" currentUrl={currentUrl}>
          edit match results
        </LinkButton>
        <LinkButton href="/admin/verify" currentUrl={currentUrl}>
          verify
        </LinkButton>
        <LinkButton href="/admin/setup" currentUrl={currentUrl}>
          setup
        </LinkButton>
      </Grid>
      <Grid container gap={1} justifyContent={'end'}>
        <LinkButton href="/admin" currentUrl={currentUrl}>
          admin home
        </LinkButton>
        <LinkButton href="/" currentUrl={currentUrl}>
          public home
        </LinkButton>
        <LinkButton href="/logout" currentUrl={currentUrl} outlined={true}>
          logout
        </LinkButton>
      </Grid>
    </div>
  );
};

export default AdminMenu;
