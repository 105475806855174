import { useEffect } from 'react';

import { postLogout } from '../utils/fetch';

const Logout = () => {
  useEffect(() => {
    localStorage.setItem('access_token', '');
    window.location.href = '/';
    postLogout();
  }, []);

  return <p>Logging out</p>;
};

export default Logout;
