import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import AdminCard from '../components/AdminCard';
import AdminFilterWeight from '../components/AdminFilterWeight';
import AdminMenu from '../components/AdminMenu';
import type {
  FlatBout,
  FlatBracket,
  Weight,
  WinCondition,
} from '../model/models';
import {
  adminFilterWeightState,
  athletesState,
  flatBracketState,
  winConditionsState,
} from '../model/store';
import {
  checkAuth,
  fetchAthlete,
  fetchFlatBracket,
  fetchWinConditions,
} from '../utils/fetch';

function bracketHtml(
  bracket: FlatBracket,
  winConditions: WinCondition[],
  adminWeightFilter?: Weight
) {
  if (Array.isArray(bracket)) {
    const filteredBracket = bracket
      .filter((b) => !adminWeightFilter || adminWeightFilter === b.weight)
      .filter(
        (bout: FlatBout) =>
          (bout.boutA !== 0 || bout.boutB !== 0) &&
          (bout.winner === 0 ||
            bout.loser === 0 ||
            bout.winConditionId === null)
      );
    if (filteredBracket.length === 0) {
      return <p>All bouts have already been added</p>;
    }
    return filteredBracket.map((bout: FlatBout) => (
      <AdminCard key={bout.bout} bout={bout} winConditions={winConditions} />
    ));
  }
  return <p>Loading</p>;
}

const AdminAdd = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [bracket, setBracket] = useRecoilState(flatBracketState);
  const setAthletes = useSetRecoilState(athletesState);
  const [winConditions, setWinConditions] = useRecoilState(winConditionsState);
  const adminWeightFilter = useRecoilValue(adminFilterWeightState);
  const location = useLocation();

  useEffect(() => {
    checkAuth().then((result) => {
      if (result) {
        setIsAuth(true);
      } else {
        const nextPage = new URLSearchParams(location.search).get('next');
        if (nextPage) {
          window.location.href = nextPage;
        } else {
          window.location.href = '/login?next=%2Fadmin%2Fadd';
        }
      }
    });
  }, []);

  useEffect(() => {
    fetchFlatBracket().then(
      (result) => {
        // setIsLoaded(true);
        setBracket(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchAthlete().then(
      (items) => {
        // setIsLoaded(true);
        setAthletes(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchWinConditions().then(
      (items) => {
        // setIsLoaded(true);
        setWinConditions(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  if (!isAuth) return <div>Loading...</div>;

  document.title = 'Admin Add';

  return (
    <div className="admin">
      <AdminMenu />
      <h2>Admin Add Results</h2>
      <AdminFilterWeight />
      <Grid container columns={1} sx={{ justifyContent: 'center' }}>
        {bracketHtml(bracket, winConditions, adminWeightFilter)}
      </Grid>
    </div>
  );
};

export default AdminAdd;
