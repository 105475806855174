import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

// import findAthleteBySeed from '../controller/findAthleteBySeed';
import type { Contact, Modes, Weight } from '../model/models';
import { contactState, modeState, selectionState } from '../model/store';

const WeightCookies = () => {
  const [selectedList, setSelectedList] = useRecoilState(selectionState);
  // const [selectedWeight, setSelectedWeight] = useRecoilState(selectedWeightState);

  function loadWeightFromCookie(selectedWeight: Weight) {
    const cookiename = `selection${selectedWeight}`;
    if (localStorage.getItem(cookiename) !== null) {
      // console.log("Cookie loaded: "+localStorage.getItem(cookiename))
      return Number(localStorage.getItem(cookiename));
    }
    return 0;
  }

  function loadWildcardFromCookie() {
    if (
      localStorage.getItem('selectionWcWeight') &&
      !Number.isNaN(parseInt(localStorage.getItem('selectionWcWeight')!, 10)) &&
      localStorage.getItem('selectionWcSeed') &&
      !Number.isNaN(parseInt(localStorage.getItem('selectionWcSeed')!, 10))
    ) {
      return {
        weight: parseInt(
          localStorage.getItem('selectionWcWeight')!,
          10
        ) as Weight,
        seed: parseInt(localStorage.getItem('selectionWcSeed')!, 10),
      };
    }
    return null;
  }

  function loadWeightsFromCookie() {
    // Clone selection state
    const newSelectedList = JSON.parse(JSON.stringify(selectedList));
    // Loop through weights already selected in cookies
    Object.keys(newSelectedList.weights).map((key) => {
      if (localStorage.getItem(`selection${key}`) !== null) {
        const loadedCookie = loadWeightFromCookie(key as unknown as Weight);
        const index = key as unknown as keyof typeof newSelectedList.weights;
        newSelectedList.weights[index] = loadedCookie;
      }
      return null;
    });
    // Load Wildcard
    if (
      localStorage.getItem('selectionWcWeight') !== null &&
      localStorage.getItem('selectionWcSeed')
    ) {
      newSelectedList.wc = loadWildcardFromCookie() ?? {
        weight: 125,
        seed: 0,
      };
    }
    setSelectedList(newSelectedList);
    console.log('Loaded previous session:', newSelectedList);
  }

  useEffect(() => {
    // console.log("Loading cookies...")
    loadWeightsFromCookie();
  }, []);

  return <></>;
};

const ModeCookies = () => {
  const setMode = useSetRecoilState(modeState);

  function loadModeFromCookie() {
    const v = localStorage.getItem('mode');
    if (v !== null) {
      setMode(v as Modes);
    }
  }

  useEffect(() => {
    loadModeFromCookie();
  }, []);

  return <></>;
};

const NameCookies = () => {
  const setName = useSetRecoilState(contactState);

  function loadNameFromCookie() {
    const first = localStorage.getItem('firstname');
    const last = localStorage.getItem('lastname');
    const alt = localStorage.getItem('alt');
    if (first && last && alt) {
      setName({ first, last, alt } as Contact);
    }
  }

  useEffect(() => {
    loadNameFromCookie();
  }, []);

  return <></>;
};

export { ModeCookies, NameCookies, WeightCookies };
