import { Button, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';

import { weightclasses } from '../model/arrays';
import type { Weight } from '../model/models';
import { adminFilterWeightState } from '../model/store';

const FilterButton = (props: { weightclass: Weight }) => {
  const [filterWeight, setFilterWeight] = useRecoilState(
    adminFilterWeightState
  );
  return (
    <Grid item>
      <Button
        variant={props.weightclass === filterWeight ? 'contained' : 'outlined'}
        color={props.weightclass === filterWeight ? 'success' : 'info'}
        onClick={() => {
          setFilterWeight(props.weightclass);
        }}
      >
        {props.weightclass}
      </Button>
    </Grid>
  );
};

const AdminFilterWeight = () => {
  return (
    <Grid container gap={1} style={{ justifyContent: 'center' }}>
      {weightclasses.map((w) => (
        <FilterButton weightclass={w} />
      ))}
    </Grid>
  );
};

export default AdminFilterWeight;
