import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d50237',
    },
    secondary: {
      main: '#FFFFFD',
    },
    info: {
      main: '#030200',
    },
  },
});

// export default function Palette() {
//   return (
//     <ThemeProvider theme={theme}>
//       <Button>Primary</Button>
//       <Button color="secondary">Secondary</Button>
//     </ThemeProvider>
//   );
// }

export default theme;
