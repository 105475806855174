import { Box, Switch } from '@mui/material';
import { useSetRecoilState } from 'recoil';

import { bracketShowConsolationState } from '../model/store';

const BracketSwitch = () => {
  const setBracketShowConsolation = useSetRecoilState(
    bracketShowConsolationState
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBracketShowConsolation(!event.target.checked);
  };

  return (
    <div
      className="bracket-switch"
      style={{ justifyContent: 'center', display: 'flex' }}
    >
      <Box
        sx={{
          borderColor: 'primary',
          border: 2,
          borderRadius: 2,
          width: 'fit-content',
          paddingY: 0.5,
          paddingX: 3,
          margin: 2,
          justifyContent: 'center',
        }}
      >
        Consolation <Switch defaultChecked={true} onChange={handleChange} />{' '}
        Championship
      </Box>
    </div>
  );
};

export default BracketSwitch;
