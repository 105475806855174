import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AdminMenu from '../components/AdminMenu';
import Create from '../components/create/Create';
import type { Script } from '../model/models';
import {
  checkAuth,
  executeScript,
  getScript,
  postScript,
} from '../utils/fetch';

const Setup = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [scripts, setScripts] = useState<Script[]>([]);
  const [scriptName, setScriptName] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    checkAuth().then((result) => {
      if (result) {
        setIsAuth(true);
      } else {
        const nextPage = new URLSearchParams(location.search).get('next');
        if (nextPage) {
          window.location.href = nextPage;
        } else {
          window.location.href = '/login?next=%2Fadmin%2Fsetup';
        }
      }
    });
  }, []);

  useEffect(() => {
    getScript().then((result) => {
      if (result && result.data) {
        setScripts(result.data);
      }
    });
  }, []);

  const save = () => {
    postScript(scriptName).then((result) => {
      if (result) {
        window.location.reload();
        return null;
      }

      alert(
        'Something went wrong trying to save your preset. Probably an invalid name.'
      );
      return false;
    });
  };

  const load = (script: Script) => {
    executeScript(script.file).then((result) => {
      if (result) {
        window.location.reload();
        return null;
      }
      return false;
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScriptName(event.target.value.trim());
  };

  if (!isAuth) return <div>Loading...</div>;

  document.title = 'Admin Setup';

  return (
    <div className="admin">
      <AdminMenu />
      <h2>Setup</h2>
      <h3>Load preset</h3>
      <Grid container sx={{ justifyContent: 'center', gap: 1 }}>
        {scripts.map((script) => (
          <Grid item>
            <Button onClick={() => load(script)}>{script.name}</Button>
          </Grid>
        ))}
      </Grid>
      <h3>Save preset</h3>
      <div>
        <input
          type="text"
          name="script_save"
          placeholder="Preset name"
          maxLength={64}
          onChange={handleChange}
          style={{
            padding: 10,
            backgroundColor: '#fbfbfb',
            maxWidth: '80%',
          }}
        />
        <Button onClick={() => save()} disabled={scriptName.length <= 0}>
          {scripts.some((script) => script.name === scriptName.toLowerCase())
            ? 'Overwrite'
            : 'Save'}
        </Button>
      </div>
      <Create />
    </div>
  );
};

export default Setup;
