import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import AdminMenu from '../components/AdminMenu';
import ControlPanel from '../components/ControlPanel';
import { settingsState } from '../model/store';
import { checkAuth, fetchAllSettings } from '../utils/fetch';

const Admin = () => {
  const setSettings = useSetRecoilState(settingsState);
  const [isAuth, setIsAuth] = useState(false);
  const location = useLocation();

  useEffect(() => {
    checkAuth().then((result) => {
      if (result) {
        setIsAuth(true);
        fetchAllSettings().then(
          (res) => {
            setSettings(res);
          },
          (_error) => {
            // setIsLoaded(true);
            // setError(error);
          }
        );
      } else {
        const nextPage = new URLSearchParams(location.search).get('next');
        if (nextPage) {
          window.location.href = nextPage;
        } else {
          window.location.href = '/login?next=%2Fadmin';
        }
      }
    });
  }, []);

  if (!isAuth) return <div>Loading...</div>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
      }}
    >
      <AdminMenu />
      <h2>Admin</h2>
      <div style={{ margin: '1rem' }}>
        <ControlPanel />
      </div>
    </div>
  );
};

export default Admin;
