import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import { useRef, useState } from 'react';

import type { Conference, School } from '../../model/models';
import {
  createDeleteSchool,
  createPostSchool,
  createUpdateSchool,
} from '../../utils/fetch';

type Props = {
  school?: School;
  schools: School[];
  setSchools: React.Dispatch<React.SetStateAction<School[] | undefined>>;
  conferences: Conference[];
};

const Component = (props: Props) => {
  const defaultSchool: School = {
    displayName: 'New',
    name: 'NEW',
    id: 0,
    conferenceId: null,
  };
  const [school, setSchool] = useState<School>(props.school ?? defaultSchool);
  const displayNameRef = useRef<HTMLSpanElement>(null);
  const nameRef = useRef<HTMLSpanElement>(null);

  function handleClick(ref: React.RefObject<HTMLSpanElement>) {
    if (ref && ref.current) {
      ref.current.setAttribute('contentEditable', 'true');
      ref.current.focus();
    }
  }

  function handleBlur(
    event: React.FocusEvent<EventTarget>,
    field: keyof School,
    ref: React.RefObject<HTMLSpanElement>
  ) {
    if (ref && ref.current) {
      ref.current.setAttribute('contentEditable', 'false');
      const newElement = {
        ...school,
        [field]: ref.current.textContent,
      };
      setSchool(newElement);
      if (props.school && newElement[field] !== props.school[field]) {
        createUpdateSchool(newElement);
      }
      const updatedItems = props.schools.map((item) =>
        item.id === newElement.id ? newElement : item
      );
      props.setSchools(updatedItems);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const newElement = {
      ...school,
      conferenceId: parseInt(event.target.value, 10),
    };
    setSchool(newElement);
    if (props.schools) {
      createUpdateSchool(newElement);
    }
  }

  function handleKeyDown(
    event: React.KeyboardEvent<HTMLSpanElement>,
    ref: React.RefObject<HTMLSpanElement>
  ) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (ref && ref.current) {
        ref.current.blur();
      }
    }
  }

  if (!props.school) {
    return (
      <Grid
        item
        key={school.id}
        sx={{
          margin: 1,
          border: 1,
          padding: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            background: 'black',
            color: 'white',
            padding: 3,
          }}
        >
          {school.id}
        </span>
        <span
          onClick={() => handleClick(displayNameRef)}
          onBlur={(e) => handleBlur(e, 'displayName', displayNameRef)}
          onKeyDown={(e) => handleKeyDown(e, displayNameRef)}
          ref={displayNameRef}
        >
          {school.displayName}
        </span>{' '}
        <span>
          (
          <span
            onClick={() => handleClick(nameRef)}
            onBlur={(e) => handleBlur(e, 'name', nameRef)}
            onKeyDown={(e) => handleKeyDown(e, nameRef)}
            ref={nameRef}
          >
            {school.name}
          </span>
          )
        </span>
        <select
          value={school.conferenceId ?? ''}
          onChange={(e) => handleChange(e)}
          style={{
            padding: 5,
            backgroundColor: '#fbfbfb',
            maxWidth: '5.5rem',
          }}
        >
          {props.conferences.map((conference) => (
            <option value={conference.id}>{conference.name}</option>
          ))}
        </select>
        <button
          onClick={() => {
            createPostSchool(school).then((result) => {
              if (result) {
                props.setSchools(result.data);
                setSchool(defaultSchool);
              }
            });
          }}
        >
          <AddIcon />
        </button>
      </Grid>
    );
  }
  return (
    <Grid
      item
      key={school.id}
      sx={{
        margin: 1,
        border: 1,
        padding: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          background: 'black',
          color: 'white',
          padding: 3,
        }}
      >
        {school.id}
      </span>
      <span
        onClick={() => handleClick(displayNameRef)}
        onBlur={(e) => handleBlur(e, 'displayName', displayNameRef)}
        onKeyDown={(e) => handleKeyDown(e, displayNameRef)}
        ref={displayNameRef}
      >
        {school.displayName}
      </span>{' '}
      <span>
        (
        <span
          onClick={() => handleClick(nameRef)}
          onBlur={(e) => handleBlur(e, 'name', nameRef)}
          onKeyDown={(e) => handleKeyDown(e, nameRef)}
          ref={nameRef}
        >
          {school.name}
        </span>
        )
      </span>
      <select
        value={school.conferenceId ?? ''}
        onChange={(e) => handleChange(e)}
        style={{
          padding: 5,
          backgroundColor: '#fbfbfb',
          maxWidth: '5.5rem',
        }}
      >
        {props.conferences.map((conference) => (
          <option value={conference.id}>{conference.name}</option>
        ))}
      </select>
      <button
        onClick={() => {
          if (school.id) {
            createDeleteSchool(school.id.toString()).then((result) => {
              if (result) {
                props.setSchools(result.data);
              }
            });
          }
        }}
      >
        <DeleteIcon />
      </button>
    </Grid>
  );
};

export default Component;
