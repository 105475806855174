export default function clearLocalStorage() {
  localStorage.removeItem('firstname');
  localStorage.removeItem('lastname');
  localStorage.removeItem('alt');
  localStorage.removeItem('mode');
  localStorage.removeItem('selection125');
  localStorage.removeItem('selection133');
  localStorage.removeItem('selection141');
  localStorage.removeItem('selection149');
  localStorage.removeItem('selection157');
  localStorage.removeItem('selection165');
  localStorage.removeItem('selection174');
  localStorage.removeItem('selection184');
  localStorage.removeItem('selection197');
  localStorage.removeItem('selection285');
  localStorage.removeItem('selectionWcWeight');
  localStorage.removeItem('selectionWcSeed');
}
