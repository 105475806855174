import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { primaryColor, secondaryColor, tertiaryColor } from '../model/arrays';
import type { Contact } from '../model/models';
import { contactState, modeState } from '../model/store';

const CreateBracketView = () => {
  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputAltName, setInputAlt] = useState('');
  const setMode = useSetRecoilState(modeState);
  const setName = useSetRecoilState(contactState);

  const valid =
    inputFirstName.length !== 0 &&
    inputLastName.length !== 0 &&
    inputAltName.length !== 0;

  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputFirstName(event.target.value.trim());
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputLastName(event.target.value.trim());
  };

  const handleChangeAlt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputAlt(event.target.value.trim());
  };

  function submit(firstname: string, lastname: string, altName: string) {
    setName({ first: firstname, last: lastname, alt: altName } as Contact);
    localStorage.setItem('firstname', firstname);
    localStorage.setItem('lastname', lastname);
    localStorage.setItem('alt', altName);
    setMode('create');
    localStorage.setItem('mode', 'create');
  }

  function handleClick() {
    submit(inputFirstName, inputLastName, inputAltName);
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && valid) {
      handleClick();
    }
  }

  return (
    <Grid
      container
      className="create-bracket"
      sx={{
        alignContent: 'center',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <Box
        sx={{
          width: '40rem',
          maxWidth: '100%',
          borderRadius: '1rem',
          border: 3,
          borderColor: tertiaryColor,
          bgcolor: primaryColor,
          boxShadow: '0px 0px 10px #333',
        }}
      >
        <h2 style={{ color: secondaryColor }}>Fill out a bracket</h2>
        <form>
          <label>
            <input
              type="text"
              name="firstname"
              placeholder="first name"
              maxLength={64}
              onChange={handleChangeFirstName}
              onKeyPress={handleKeyPress}
              style={{
                width: 170,
                padding: 10,
                margin: 3,
                backgroundColor: '#fbfbfb',
              }}
            />
            <input
              type="text"
              name="lastname"
              placeholder="last name"
              maxLength={64}
              onChange={handleChangeLastName}
              onKeyPress={handleKeyPress}
              style={{
                width: 170,
                padding: 10,
                margin: 3,
                backgroundColor: '#fbfbfb',
              }}
            />
            <p
              style={{
                marginTop: 4,
                marginBottom: 8,
                color: secondaryColor,
              }}
            >
              <i>Your name will be publicly displayed on our leaderboard.</i>
            </p>
            <input
              type="text"
              name="alt"
              placeholder="cell phone number for texting"
              maxLength={128}
              onChange={handleChangeAlt}
              onKeyPress={handleKeyPress}
              style={{
                padding: 10,
                backgroundColor: '#fbfbfb',
                width: '370px',
                maxWidth: '80%',
              }}
            />
            <p
              style={{
                marginTop: 4,
                marginBottom: 8,
                color: secondaryColor,
              }}
            >
              <i>
                Kept private. Only used to contact you if something is wrong.
              </i>
            </p>
          </label>
        </form>
        <Button
          disabled={!valid}
          onClick={() => handleClick()}
          color="info"
          variant={!valid ? 'outlined' : 'contained'}
          sx={{ margin: 1 }}
        >
          {!valid ? 'Start by entering your name' : 'Fill out'}
        </Button>
      </Box>
    </Grid>
  );
};

export default CreateBracketView;
